import * as React from "react";
import {
  Box,
  Input,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import "jquery-mask-plugin";
import { useUserStore } from 'store/reducers/user';
import "./UserReports.css";
import "bootstrap";
import { FaEnvelope, FaPaperclip, FaTimes} from 'react-icons/fa';
import { CSSProperties } from "react";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { notifyError } from "utils/notification";


export default function UserReports() {
	//react-rich-text-editor
	const auth = useUserStore((state) => state.auth);

	const [title, setTitle] = React.useState('');
	const [priority, setPriority] = React.useState('Média');
	const [selectedFiles, setSelectedFiles] = React.useState([]);
	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const [isLoading, setIsLoading] = React.useState(false);
	const [modalResposta, setModalResposta] = React.useState(false);
	const [ticketNumber, setTicketNumber] = React.useState('');
	const [dataselect, setDataselect] = React.useState([]);
	const [dataselectfin, setDataselectFin] = React.useState([]);
	const [expandedId, setExpandedId] = React.useState(null);

	const [ticketN, setTicket] = React.useState('');
	const [status, setStatus] = React.useState(0);
	React.useEffect(() => {
		fetchDat();
	}, []);

	const fetchDat = async () => {
		setIsLoading(true);
		try {
			const response = await axios.get(
			  `${process.env.REACT_APP_API_URL}/helpdesk`,
			  {
				params: {
				user: auth.user.id,
			    },
				headers: {
				  "Content-Type": "multipart/form-data",
				  Authorization: `Bearer ${auth.token}`,
				}
			  }
			);
			const parsedData: Array<{
				id: number;
				user_id: string;
				ticket: string;
				titulo: string;
				descricao: string;
				prioridade: string;
				status: string;
				status_data: string;
				created_at: string;
				updated_at: string;
				status_code: string;
				arquivos: Array<{
					id: number;
					help_desks_id: number;
					titulo: string;
					arquivo: string;
					created_at: string;
					updated_at: string;
				}>;
			}> = response.data;

			const filteredData = parsedData.filter(item => item.status_code !== "4");
			const filteredDataFin = parsedData.filter(item => item.status_code === "4");
			setDataselect(filteredData);
			setDataselectFin(filteredDataFin);
		} catch (error) {
		  	console.log(error);
		}finally {
		  	setIsLoading(false);
		}
	}

	const handleCloseResposta = () => {
		setModalResposta(false);
	};



	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		const formData = {
		title,
		description: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
		priority,
		files: selectedFiles,
		user: {
			id: auth.user.id,
		},
		};
		let texto = convertToRaw(editorState.getCurrentContent())

		if(texto.blocks[0].text !== ''){
			try {
				const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/helpdesk`,
				formData,
				{
					headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${auth.token}`
					}
				}
				);

				const parsedData = response.data;
				setTicketNumber(parsedData.ticket_number);

			} catch (error) {
				console.log(error);

			}finally {
				setModalResposta(true);
				setTitle('');
				setPriority('Média');
				setSelectedFiles([]);
				setEditorState(EditorState.createEmpty());
				setIsLoading(false);
				fetchDat();
			}
		}else{
			setIsLoading(false);
			notifyError('Descrição é obrigatório');

		}

	};

	const renderPreloader = () => {
		return isLoading ? (<div className="preloader-overlay">
		<div className="preloader-content">
		  <div className="preloader-spinner"></div>
		</div>
	  	</div>) : null;
	};


	const handleFileSelect = (event: any) => {
		const files = event.target.files;
		const filesArray = Array.from(files).map((file: any) => {
		  const thumbnail = URL.createObjectURL(file);
		  return { file, thumbnail };
		});
		setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filesArray]);
	};

	const handleRemoveFile = (index: any) => {
		setSelectedFiles((prevSelectedFiles) => {
		  const updatedFiles = [...prevSelectedFiles];
		  updatedFiles.splice(index, 1);
		  return updatedFiles;
		});
	};

	const handleEditorChange = (state: any) => {
		setEditorState(state);
	};



	const toolbarOptions = {
		options: ['inline', 'history'],
		inline: {
		  options: ['bold', 'italic', 'underline', 'strikethrough'],
		},

		history: {
		  options: ['undo', 'redo'],
		},
	};


	const containerStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '10px',
	  };

	  const bolaStyle: CSSProperties = {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		backgroundColor: '#8A05BE',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		fontSize: '24px',
		fontWeight: 'bold',
	  };

	  const bolaStyleEmpyt: CSSProperties = {
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		backgroundColor: '#fff',
		border: '2px solid #8A05BE',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'black',
		fontSize: '24px',
		fontWeight: 'bold'
	  };

	  const linhaStyle = {
		width: '16%',
		height: '6px',
		backgroundColor: '#8A05BE',
	  };
	  const linhaStyleEmpty = {
		width: '16%',
		height: '6px',
		backgroundColor: 'grey',
	  };

	  const handleTicketClick = (id: any, ticket:any, status:any) => {
		handlechat(ticket, status);

		if (expandedId === id) {
		  setExpandedId(null); // Fecha o ticket se ele já estiver expandido
		} else {
		  setExpandedId(id); // Expande o ticket clicado
		}
	  };

	  function converteData(inputString: any) {
		let date = new Date(inputString);
			let offsetInHours = date.getTimezoneOffset() / 60;
			date.setHours(date.getUTCHours() - offsetInHours);
			let day: string | number = date.getUTCDate();
			let month: string | number = date.getUTCMonth() + 1;
			let year: string | number = date.getUTCFullYear();
			let hours: string | number = date.getUTCHours();
			let minutes: string | number = date.getUTCMinutes();
			let seconds: string | number = date.getUTCSeconds();

			day = day < 10 ? '0' + day : day;
			month = month < 10 ? '0' + month : month;
			hours = hours < 10 ? '0' + hours : hours;
			minutes = minutes < 10 ? '0' + minutes : minutes;
			seconds = seconds < 10 ? '0' + seconds : seconds;

			let outputString = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds;

			return outputString;
	  }


	function convertDescriptionToHtml(description: any) {
		if (!description) {
			return '';
		}
		const bloco = JSON.parse(description)
		const contentStateDesc = convertFromRaw(bloco);
		const html = convertToHTML(contentStateDesc);
		return html;
	}

	const [chatMessage, setChatMessage] = React.useState("");
	const [chatResponses, setChatResponses] = React.useState([]);

	const handlechat = async (ticket: any, status:any) => {
		setTicket(ticket);
		setStatus(status);
		try {
			const formData = new FormData();
			formData.append("ticket", ticket);

		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/helpdeskchat`,
			formData,
			{
				headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${auth.token}`
				}
			}
		);

		setChatResponses(response.data);
		} catch (error) {

		}finally {


		}
	};

	const handleSendMessage = async (ticket: any) => {

	if(chatMessage !== ''){
		setIsLoading(true);
		try {
			const formData = new FormData();

			// Append form data
			formData.append("id", auth.user.id+'');
			formData.append("texto", chatMessage);
			formData.append("ticket", ticket);

		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/helpdeskresposta`,
			formData,
			{
				headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${auth.token}`
				}
			}
		);

		setChatResponses(response.data);
		setChatMessage("");
		} catch (error) {

		}finally {
			setIsLoading(false);

		}
	}
	};

	const chatBoxRef = React.useRef(null);

	React.useEffect(() => {
	if (chatBoxRef.current) {
		chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
	}
	}, [chatResponses]);

	const handleKeyDown = (e: any, ticket: any) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleSendMessage(ticket);

		}
	};


	function formatDate(dateTimeString:any) {
		const dateTime = new Date(dateTimeString);

		const day = String(dateTime.getDate()).padStart(2, '0');
		const month = String(dateTime.getMonth() + 1).padStart(2, '0');
		const year = dateTime.getFullYear();
		const hours = String(dateTime.getHours()).padStart(2, '0');
		const minutes = String(dateTime.getMinutes()).padStart(2, '0');
		const seconds = String(dateTime.getSeconds()).padStart(2, '0');

		return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
	}

	React.useEffect(() => {
		let interval: any;

		if (status == 2) {

		  interval = setInterval(() => {
			handlechat(ticketN, status);
		  }, 5000);
		}

		// Função de limpeza: limpa o intervalo quando a modal for fechada
		return () => {
		  if (interval) {
			clearInterval(interval);
		  }
		};
	}, [status]);

	return(
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Modal  isOpen={modalResposta} onClose={handleCloseResposta}>
				<ModalContent>
					<ModalHeader>Seu chamado foi aberto!</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						Esse é seu ticket do chamado:<br/>
						<span style={{width: '100%', fontFamily: 'monospace', fontSize: '26px', fontWeight: 'bold', textAlign: 'center'}}>{ticketNumber}</span>
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={handleCloseResposta}>Fechar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>




			{renderPreloader()}
			<Box overflow={"auto"} maxHeight={"85vh"} minHeight={"85vh"}>
				<Box display="flex">
					<Tabs isLazy isFitted  width="100%">
						<TabList bg="white" borderBottom="1px" borderColor="gray.200" boxShadow="sm">
						<Tab color="black">Tickets Abertos</Tab>
						<Tab color="black">Abrir Chamado</Tab>
						<Tab color="black">Tickets Fechados</Tab>
						</TabList>

						<TabPanels>
						<TabPanel p={0} pt={1}>
							<Box bg="white" p={4} borderWidth="1px" borderColor="gray.200" boxShadow="sm">
							<div>
								{dataselect.map((field) => (
									<div
									key={field.id}
									style={{
										border: '1px solid',
										boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
										margin: '10px',
										padding: '10px',
										cursor: 'pointer',
									}}

									>
									<div>
										<div style={containerStyle} onClick={() => handleTicketClick(field.id, field.ticket, field.status_code)}>
										<div>Ticket: <span style={{fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold'}}>{field.ticket}</span></div>
										<div>Abertura: <span style={{fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold'}}>{converteData(field.created_at)}</span></div>
										<div>Movimento: <span style={{fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold'}}>{converteData(field.updated_at)}</span></div>
										</div>
										<div style={containerStyle}>

											<br></br>
											<div style={field.status_code>=0?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>1</span>
											<label style={{fontSize: '12px', color: 'black'}}>Aberto</label>
											</div>
											<div style={field.status_code>=0?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=1?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>2</span>
											<label style={{fontSize: '12px', color: 'black'}}>Analise</label>
											</div>
											<div style={field.status_code>=1?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=2?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>3</span>
											<label style={{fontSize: '12px', color: 'black'}}>Resposta</label>
											</div>
											<div style={field.status_code>=2?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=3?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>4</span>
											<label style={{fontSize: '12px', color: 'black'}}>Solução</label>
											</div>
											<div style={field.status_code>=3?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=4?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>5</span>
											<label style={{fontSize: '12px', color: 'black'}}>Fechado</label>
											</div>
										</div>
									</div>
									{expandedId === field.id && (
										<div style={{marginTop: '20px'}}>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Título: {field.titulo}</div>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Descrição: <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHtml(field.descricao) }}/></div>
										<div style={{background: '#E5DDD5', padding: '10px', borderRadius: '5px', overflowY: 'scroll', maxHeight: '200px'}} ref={chatBoxRef}>
										{
											chatResponses.map((response) => (
												<div style={{display: 'flex', justifyContent: response.user_id !== 0 ? 'flex-end' : 'flex-start'}}>
													<div style={{
														backgroundColor: response.user_id !== 0 ? '#DCF8C6' : '#FFFFFF',
														padding: '5px',
														borderRadius: '8px',
														margin: '5px 0',
														maxWidth: '80%',
														wordBreak: 'break-word'
													}}>
														{response.mensagem}

													<div style={{ fontSize: '10px', color: 'gray', alignSelf: response.user_id !== 0 ? 'flex-end' : 'flex-start' }}>
														{formatDate(response.created_at)}
													</div></div>
												</div>

											))
										}
										</div>
										{
											field && field.status_code == 2 ? (
												<div style={{display: 'flex', alignItems: 'center', border: '1px solid black', borderRadius: '3px', overflow: 'hidden'}}>
													<div style={{background: 'gray', padding: '10px'}}>
														<span style={{color: 'white'}}><FaEnvelope></FaEnvelope></span>
													</div>
													<input
														type="text"
														style={{ flex: 1, border: 'none', padding: '10px', borderRadius: '3px' }}
														value={chatMessage}
														onChange={(e) => setChatMessage(e.target.value)}
														onKeyDown={(e) => handleKeyDown(e, field.ticket)} // Adicione este atributo para chamar a função handleKeyDown
														placeholder="Digite sua mensagem..."
													/>
													<button
														style={{ borderRadius: '3px', backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer' }}
														onClick={() => handleSendMessage(field.ticket)} // Atualize o atributo para onClick ao invés de onKeyDown
													>
														Enviar
													</button>
												</div>

											) : null
										}
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Prioridade: {field.prioridade}</div>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Arquivos:
											{field.arquivos.map((arquivo: any) => (
											<div>
												{arquivo.titulo}<img src={`${process.env.REACT_APP_API_URL_IMAGE}/storage/` + arquivo.arquivo} alt="Thumbnail" style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', width: '100%', height: 'auto' }} />
												<br></br>
											</div>
											))}
										</div>
										</div>
									)}
									</div>
								))}
								</div>
							</Box>
						</TabPanel>
						<TabPanel p={0} pt={1}>
							<Box bg="white" p={4} borderWidth="1px" borderColor="gray.200" boxShadow="sm" overflow={"auto"}>
							<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label className="form-label">Título do Chamado:</label>
								<input type="text" className="form-control" value={title} onChange={(event) => setTitle(event.target.value)} required />
							</div>

							<div className="form-group">
								<label className="form-label">Descrição do Problema:</label>
								<div style={{ border: "1px solid #E2E8F0", borderRadius: "4px", margin: "0px", padding: '0px', height: '250px', overflow: 'auto' }} >
								<Editor
									editorState={editorState}
									onEditorStateChange={handleEditorChange}
									toolbar={toolbarOptions}
									/>
								</div>
								<Button as="label" htmlFor="fileInput" leftIcon={<FaPaperclip />} colorScheme="teal" variant="outline">
									Anexar
									<Input id="fileInput" type="file" accept="image/*" onChange={handleFileSelect} style={{ display: 'none' }} multiple />
								</Button>
								<ul>
									{selectedFiles.map((file, index) => (
										<li key={index} style={{ display: 'flex', alignItems: 'center', border: "1px solid #E2E8F0", borderRadius: "4px", fontFamily: "monospace", fontSize: "18px"}}>
										<span>{`${index + 1}. `}</span>
										<img src={file.thumbnail} alt="Thumbnail" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
										<span>{` ${file.file.name}`}</span>
										<FaTimes style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleRemoveFile(index)} />
										</li>
									))}
								</ul>
							</div>
							<div className="form-group">
								<label className="form-label">Prioridade:</label>
								<select className="form-control" value={priority} onChange={(event) => setPriority(event.target.value)}>
								<option value="Alta">Alta</option>
								<option value="Média">Média</option>
								<option value="Baixa">Baixa</option>
								</select>
							</div>


							<button type="submit" className="btn btn-custom">Abrir Chamado</button>
							</form>
							</Box>
						</TabPanel>
						<TabPanel p={0} pt={1}>
							<Box bg="white" p={4} borderWidth="1px" borderColor="gray.200" boxShadow="sm">
							<div>
								{dataselectfin.map((field) => (
									<div
									key={field.id}
									style={{
										border: '1px solid',
										boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
										margin: '10px',
										padding: '10px',
										cursor: 'pointer',
									}}
									onClick={() => handleTicketClick(field.id, field.ticket, field.status_code)}
									>
									<div>
										<div style={containerStyle}>
										<div>Ticket: <span style={{fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold'}}>{field.ticket}</span></div>
										<div>Abertura: <span style={{fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold'}}>{converteData(field.created_at)}</span></div>
										<div>Movimento: <span style={{fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold'}}>{converteData(field.status_data)}</span></div>
										</div>
										<div style={containerStyle}>

											<br></br>
											<div style={field.status_code>=0?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>1</span>
											<label style={{fontSize: '12px', color: 'black'}}>Aberto</label>
											</div>
											<div style={field.status_code>=0?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=1?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>2</span>
											<label style={{fontSize: '12px', color: 'black'}}>Analise</label>
											</div>
											<div style={field.status_code>=1?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=2?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>3</span>
											<label style={{fontSize: '12px', color: 'black'}}>Resposta</label>
											</div>
											<div style={field.status_code>=2?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=3?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>4</span>
											<label style={{fontSize: '12px', color: 'black'}}>Solução</label>
											</div>
											<div style={field.status_code>=3?linhaStyle:linhaStyleEmpty}></div>
											<div style={field.status_code>=4?bolaStyle:bolaStyleEmpyt}>
											<span style={{paddingTop: '20px'}}>5</span>
											<label style={{fontSize: '12px', color: 'black'}}>Fechado</label>
											</div>
										</div>
									</div>
									{expandedId === field.id && (
										<div style={{marginTop: '20px'}}>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Título: {field.titulo}</div>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Descrição: <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHtml(field.descricao) }}/></div>
										<div style={{background: '#E5DDD5', padding: '10px', borderRadius: '5px', overflowY: 'scroll', maxHeight: '200px'}} ref={chatBoxRef}>
										{
											chatResponses.map((response) => (
												<div style={{display: 'flex', justifyContent: response.user_id !== 0 ? 'flex-end' : 'flex-start'}}>
													<div style={{
														backgroundColor: response.user_id !== 0 ? '#DCF8C6' : '#FFFFFF',
														padding: '5px',
														borderRadius: '8px',
														margin: '5px 0',
														maxWidth: '80%',
														wordBreak: 'break-word'
													}}>
														{response.mensagem}

													<div style={{ fontSize: '10px', color: 'gray', alignSelf: response.user_id !== 0 ? 'flex-end' : 'flex-start' }}>
														{formatDate(response.created_at)}
													</div></div>
												</div>

											))
										}
										</div>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Prioridade: {field.prioridade}</div>
										<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>Arquivos:
											{field.arquivos.map((arquivo: any) => (
											<div>
												{arquivo.titulo}<img src={`${process.env.REACT_APP_API_URL_IMAGE}/storage/` + arquivo.arquivo} alt="Thumbnail" style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', width: '100%', height: 'auto' }} />
												<br></br>
											</div>
											))}
										</div>
										</div>
									)}
									</div>
								))}
								</div>
							</Box>
						</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
		</Box>
	);

}


