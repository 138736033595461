
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
import Navbar from 'components/navbar/NavbarRegister';
import Sidebar from 'components/sidebar/SidebarRegister';
import { SidebarContext } from 'contexts/SidebarContext';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';
import { useUserStore } from 'store/reducers/user';

export default function Dashboard(props:any) {



  const auth = useUserStore((state) => state.auth);

  const [aprovado, setAprovado] = useState(false);


  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getActiveRoute = (routes:any) => {
    let activeRoute = 'Seja bem vindo!';
    for (let i = 0; i < routes.length; i++) {
      if (

        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes:any) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes:any) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  const getSubRoutes = (routes: any) => {
    let subroutes = []
    const routesArray = routes.filter(route => route.submenus).map((route, index) => {
      return route.submenus.map(submenu => {
          subroutes.push({
            layout: route.layout,
            parentPath: route.path,
            ...submenu})
      })
    })

    return subroutes.map(subroute =>(
      <Route

        path={subroute.layout + subroute.path}
        component={subroute.component}
      />
    ) )


  }
  const getRoutes = (routes:any) => {
    const approved:string = auth.user.analise
    if (approved !== "Aprovado") {
      return routes.map((route:any, key:any) => {
        if (
          route.layout === '/admin' &&
          (route.path === '/dashboard' || route.path === '/perfil' || route.path === '/helpdesk')
        ) {
          return (
            <Route
            exact
              path={route.layout  + route.path}
              component={route.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return routes.map((route, index) => {
        if (route.submenus && route.submenus.length > 0) {
          // Se a rota tiver submenus, crie uma rota pai com sub-rotas aninhadas
          return (
            <Route key={index} path={route.layout + route.path} component={route.component}>

            </Route>
          );
        } else {
          // Se a rota não tiver submenus, crie uma rota simples
          return (
            <Route
              key={index}
              path={route.layout + route.path}
              component={route.component}
            />
          );
        }
      });
    }
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();

  useEffect(() => {

   if(!auth.user.complete_register){
    window.location.href = '#/admin/perfil';
   }else{
    window.location.href = '#/admin/dashboard';
   }
	}, [aprovado]);


  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 190px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 240px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"

        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText="Horizon UI Dashboard PRO"
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes(routes)}
                {getSubRoutes(routes)}
                {/* <Redirect from="/" to="/admin" /> */}
              </Switch>
            </Box>
          ) : (
            props.children
          )}

          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}

