import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { H1 } from "components/typograph";
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format, parseISO } from "date-fns";
import { editProfileSeller } from "services/userRequests"; // Adicionada a importação de editProfileSeller
import CustomButton from "styleguide/Button";
import { RowFooterButton } from "../../styles";
import { usePerfilPage } from "../../store";

const EditingStep = ({ user }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(user.name || "");
  const [email, setEmail] = useState(user.email || "");
  const [motherName, setMotherName] = useState(user.mother_name || "");
  const [cellphone, setCellphone] = useState(user.cellphone || "");
  const [cpf, setCpf] = useState(user.cpf || "");
  const [cpfValido, setCpfValido] = useState(true);
  const [birthDate, setBirthDate] = useState(user.birth_date ? parseISO(user.birth_date) : null);
  const [cep, setCep] = useState(user.zipcode || "");
  const [address, setAddress] = useState(user.address || "");
  const [city, setCity] = useState(user.city || "");
  const [state, setState] = useState(user.state || "");
  const [complement, setComplement] = useState(user.complement || "");
  const [number, setNumber] = useState(user.number ? user.number.toString() : "");
  const [isEdited, setIsEdited] = useState(false);
  const [fade, setFade] = useState(false);

  const { onboardingPage, setOnBoardingPage } = usePerfilPage();

  const formatCpf = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    let formattedValue = cleanedValue;
    if (cleanedValue.length <= 11) {
      formattedValue = cleanedValue.replace(/(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (match, p1, p2, p3, p4) => {
        return `${p1}${p2 ? `.${p2}` : ''}${p3 ? `.${p3}` : ''}${p4 ? `-${p4}` : ''}`;
      });
    }
    return formattedValue;
  };

  const handleCpfChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 11) {
      const formattedValue = formatCpf(value);
      setCpf(formattedValue);
      setIsEdited(true);
    }
  };

  const handleCpfValidation = () => {
    function TestaCPF(strCPF) {
      let Soma;
      let Resto;
      Soma = 0;
      strCPF = strCPF.replace(/[.-]/g, '');
      if (strCPF === "00000000000") return false;
      for (let i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }

    if (cpf.length === 14) {
      const cpfSemMascara = cpf.replace(/[.-]/g, '');
      const isValidCpf = TestaCPF(cpfSemMascara);
      setCpfValido(isValidCpf);
    }
  };

  const handlePreviousStep = () => {
    setFade(false);
    setTimeout(() => {
      setOnBoardingPage(0);
    }, 350);
  };

  const handleNextStep = () => {
    setFade(false);
    setTimeout(() => {
      setOnBoardingPage(1);
    }, 350);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const formData = {
      user_id: user.id,
      name,
      email,
      mother_name: motherName,
      cellphone,
      cpf,
      birth_date: birthDate ? format(birthDate, "yyyy-MM-dd") : null,
      cep,
      address,
      city,
      state,
      complement,
      number,
    };
    setLoading(true);
    const response = await editProfileSeller(formData);
    setLoading(false);
    if (response && response.success) {
      handleNextStep();
    }
    setIsEdited(false);
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setIsEdited(true);
  };

  return (
    <><Box
      style={{
        backgroundColor: "#fff",
        flex: "1",
        alignItems: "center",
        padding: "20px",
        borderRadius: "12px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        margin: "20px",
        maxWidth: "800px",
        width: "100%"
      }}
    >

      {user.complete_register && user.analise === "Aprovado" && (
        <Box
          style={{
            background: "linear-gradient(135deg, #3b3b98 0%, #6a1b9a 100%)", // Gradiente roxo escuro
            padding: "25px",
            borderRadius: "15px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.6)", // Sombra mais escura
            border: "2px solid #4a148c", // Borda roxa escura
            marginBottom: "20px",
            textAlign: "center",
            color: "#fff" // Texto branco para contraste
          }}
        >
          <H1 style={{ color: "#fff" }}>Você foi aprovado!</H1>
        </Box>
      )}

      <Box mt={6} mb={6}>
        <b>Complete os campos abaixo e clique em "Salvar e Avançar".</b>
      </Box>

      <form onSubmit={handleEdit}>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem>
            <Box
              backgroundColor="#f7f7f7"
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
              borderRadius="12px"
              p={4}
            >
              <FormControl>
                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Nome Completo<span style={{ color: brandStars }}>* (30 max. caracteres)</span>
                </FormLabel>
                <Input
                  id="nomeCompleto"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Nome completo"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  maxLength={30}
                  value={name}
                  onChange={handleChange(setName)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Email<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="email"
                  placeholder="email@dominio.com"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  mb="24px"
                  size="lg"
                  type="email"
                  variant="auth"
                  value={email}
                  onChange={handleChange(setEmail)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />
                
              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Data de Nascimento<span style={{ color: brandStars }}>*</span>
              </FormLabel>

              <Box
                borderWidth="0px"
                borderRadius="md"
                borderColor="gray.200"
                _hover={{ borderColor: "purple.500" }}
                _focusWithin={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                mb="14px"
                p={2} // Add padding for better look
                ml={-2}
              >
                <DatePicker
                  value={birthDate}
                  onChange={(date) => { setBirthDate(date); setIsEdited(true); }}
                  format="dd/MM/yyyy"
                  style={{
                    width: "140%",
                    borderRadius: "12px",
                    borderColor: "purple"
                  }}
                  oneTap
                  block
                />
              </Box>

                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Nome da Mãe<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="motherName"
                  placeholder="Nome da mãe"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  mb="24px"
                  size="lg"
                  type="text"
                  variant="auth"
                  value={motherName}
                  onChange={handleChange(setMotherName)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Telefone<span style={{ color: brandStars }}>*</span>
              </FormLabel>
              <Input
                id="cellphone"
                placeholder="(99) 9999-9999"
                className="input-group-text text-left"
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                type="text"
                variant="auth"
                value={cellphone}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                  value = value.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
                  setCellphone(value);
                  setIsEdited(true);
                }}
                _hover={{ borderColor: "purple.500" }}
                _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
              />



                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  CPF<span style={{ color: brandStars }}>*</span> {!cpfValido && <p style={{ color: 'red' }}>CPF inválido!</p>}
                </FormLabel>
                <Input
                  id="cpf"
                  placeholder="000.000.000-00"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  mb="24px"
                  size="lg"
                  type="text"
                  variant="auth"
                  value={cpf}
                  onKeyUp={handleCpfValidation}
                  onChange={handleCpfChange}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

              </FormControl>
            </Box>
          </GridItem>

          <GridItem>
            <Box
              backgroundColor="#f7f7f7"
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
              borderRadius="12px"
              p={4}
            >
              <FormControl>
                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  CEP<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="cep"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="00000-000"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  value={cep}
                  onChange={handleChange(setCep)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Estado<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="state"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Estado (SP, RJ, MG, etc.)"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  value={state}
                  maxLength={2} // Limita a 2 caracteres
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase(); // Converte para letras maiúsculas
                    setState(value);
                    setIsEdited(true);
                  }}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />


                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Cidade<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="city"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Cidade"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  value={city}
                  onChange={handleChange(setCity)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Endereço<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="address"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Endereço"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  value={address}
                  onChange={handleChange(setAddress)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Número<span style={{ color: brandStars }}>*</span>
                </FormLabel>
                <Input
                  id="number"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Número"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  value={number}
                  onChange={handleChange(setNumber)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />

                <FormLabel
                  ms="1px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Complemento
                </FormLabel>
                <Input
                  id="complement"
                  className="input-group-text text-left"
                  fontSize="sm"
                  placeholder="Complemento"
                  mb="14px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  value={complement}
                  onChange={handleChange(setComplement)}
                  _hover={{ borderColor: "purple.500" }}
                  _focus={{ borderColor: "purple.500", boxShadow: "0 0 5px rgba(128, 0, 128, 0.5)" }}
                />
              </FormControl>
            </Box>
          </GridItem>
        </Grid>

      </form>
    </Box><RowFooterButton>
        <CustomButton
          isFullWidth={false}
          variant={"tertiary"}
          type="button"
          onClick={handlePreviousStep}
        >
          Voltar
        </CustomButton>
        <CustomButton
          isFullWidth={false}
          disabled={!isEdited || !cpfValido || cpf.length < 14}
          variant={"primary"}
          onClick={handleEdit}
        >
          Salvar e Avançar
        </CustomButton>
      </RowFooterButton></>
  );
}

export default EditingStep;