import React, { useEffect } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  Fade,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useMutation } from "@tanstack/react-query";
import api from "services/api";
import { useEmailStore } from "store/reducers/email";
import { useUserStore } from "store/reducers/user";
import $ from "jquery";
import "jquery-mask-plugin";
import "bootstrap";
import {Text as TextGuide} from "styleguide/Typograph"
import {CustomInput} from "styleguide/Input"
import {CustomButton} from "styleguide/Button"
import {toastPromise, toastError} from "styleguide/Toastify"

function SignIn() {
  const { push } = useHistory();
  const emailStore = useEmailStore((state) => state.email);

 
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [email, setEmail] = React.useState(emailStore);
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const updateToken = useUserStore((state) => state.updateToken);
  const updateUser = useUserStore((state) => state.updateUser);
  const updateTokenShopify = useUserStore((state) => state.updateTokenShopify);
  const updateContractId = useUserStore((state) => state.updateContractId);
  const updateMembershipPlanId = useUserStore(
    (state) => state.updateMembershipPlanId
  );

  const handleLogin = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();

      setLoading(true);

      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email,
          password,
        }
      );
      

      if (response.data.token) {
        updateToken(response.data.token);
        updateTokenShopify(response.data.token_shopify || null);
        updateUser(response.data.data);
        try {
          if (response.data.contract.id) {
            updateContractId(response.data.contract.id!);
          }
          if (response.data.contract.membership_plan_id) {
            updateMembershipPlanId(response.data.contract.id!);
          }
        } catch (err) {}

        if (response.data.data.mail_active) {
          if (response.data.data.zipcode == null) {
            push({
              pathname: "/auth/continue",
              state: {
                name: response.data.data.name,
                email: response.data.data.email,
              },
            });
          } else {

           
              
              push("/admin");
           
          }
        } else {
          push("/auth/confirm");
        }
      } else {
        toastError(`Seu email ou senha estão inválidos! Favor verifique e tente
        novamente!`);
      }
    } catch (error) {
      toastError(`Seu email ou senha estão inválidos! Favor verifique e tente
      novamente!`);
    } finally {
      setLoading(false);
    }
  };

  $(() => {
    $("#imagemLogo").attr("style", "position:fixed;");
  });

  const [modalshow, setModal] = React.useState(false);
  const handleClose = () => {
    setModal(false);
    push("/auth/sign-in");
  };
  const handleShow = () => setModal(true);

  const [modalshowactive, setModalactive] = React.useState(false);
  const handleCloseactive = () => {
    setModalactive(false);
    push("/auth/sign-in");
  };
  const handleShowactive = () => setModalactive(true);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <TextGuide
            type={'h1'}
          >
            Entrar
          </TextGuide>
          <TextGuide type={'h6'}>
            Entre com seu Email e Senha!
          </TextGuide>
        </Box>

       

        <Modal isOpen={modalshowactive} onClose={handleCloseactive}>
          <ModalContent>
            <ModalHeader>Em analise!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Sua solicitação de conta ainda não foi verificada, mas logo
              enviaremos um email informando o resultado!
            </ModalBody>
            <ModalFooter>
              <Button variant="primary" onClick={handleCloseactive}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
         
          <Flex align="center" mb="25px">
            {/* <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator /> */}
          </Flex>
          <form
            onSubmit={(e) => {
              handleLogin(e);
            }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <CustomInput
                id="email"
                focusColor={'primary'}
                type="email"
                placeholder="email@dominio.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></CustomInput>
              {/* <Input
                id="email"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@simmmple.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              /> */}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <CustomInput
                id="senha"
                focusColor={'primary'}
                type="password"
                placeholder="Min. 8 dígitos"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              
              <Flex justifyContent="flex-end" mb="24px">
                <NavLink to="/auth/forgot-password">
                  <Text color={textColorBrand} fontSize="sm" fontWeight="500">
                    Esqueci minha senha
                  </Text>
                </NavLink>
              </Flex>
             
              <CustomButton 
                variant={"primary"} 
                isFullWidth={true}
                type="submit"
                isLoading={loading}
                >Entrar</CustomButton>
            </FormControl>
          </form>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Não é registrado?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Criar uma conta
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
