import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Checkbox } from "@chakra-ui/react";
import { useUserStore } from "store/reducers/user";
import axios from "axios";
import { usePerfilPage } from "../../store/index";
import { BankAccountData } from "../../models/types";
import { FormRow, Row, Label, TextCenter, DisplayRow } from "../../styles";
import { Container } from "./styles";
import { Text as CustomText } from "styleguide/Typograph";
import { CustomInput } from "styleguide/Input";
import SearchableSelect from "components/form/SearchableSelect";

const PersonalBankingStep = ({ setFormIsValid, setFormInfo }) => {
  const [dataselectb, setDataselectb] = React.useState([]);
  const [dataDict, setDataDict] = React.useState({});

  const [auth, user] = useUserStore((state) => [state.auth, state.user]);
  const explodeConta = (
    agencia: string | null
  ): { numero: string; digito: string } => {
    if (!agencia || !agencia.includes("-") || agencia.length === 0) {
      return null;
    }

    // Divida a string da agência em duas partes usando o hífen como delimitador
    const [numero, digito] = agencia.split("-");

    // Se qualquer parte estiver faltando ou o dígito tiver mais de um caractere, retorne null
    if (!numero || !digito || digito.length !== 1) {
      return null;
    }

    // Retorne o número da agência e o último dígito
    return {
      numero,
      digito,
    };
  };

  const { watch, setValue } = useForm<BankAccountData>({
    defaultValues: {
      banco_id: user?.banco_id!,
      agencia: explodeConta(user?.agencia)?.numero! || user?.agencia,
      agenciaLast: explodeConta(user?.agencia)?.digito!,
      conta: explodeConta(user?.conta)?.numero! || user?.conta,
      contaLast: (explodeConta(user?.conta)?.digito !== undefined) ? explodeConta(user?.conta)?.digito! : '0',
      n_pix: user?.n_pix!,
      checkForm: false,
    },
  });

  const formData = watch();

  const fetchData = useCallback(async () => {
    try {
      const responseB = await axios.get(
        "https://jsonpdataproxy.appspot.com/?url=https%3A%2F%2Fwww.bcb.gov.br%2Fpom%2Fspb%2Festatistica%2Fport%2FParticipantesSTRport.csv&max-results=1000&type=CSV&format=json"
      );

      const parsedDataB = responseB.data.data;

      let preFilteredparsedDataB = parsedDataB
        .filter(
          (field) => field[2] !== "n/a" && field[2] !== "" && field[2] !== null
        )
        .sort((a, b) => {
          const valueA = a[1] || "";
          const valueB = b[1] || "";
          return valueA.localeCompare(valueB);
        });

      setDataselectb(preFilteredparsedDataB);

      if (user.banco_id) {
        setValue("banco_id", user.banco_id);
      }
    } catch (error) {
      // Handle error
    }
  }, []);

  const validateBancoId = () => {
    return !!formData.banco_id;
  };

  const validateAgencia = () => {
    return !!formData.agencia;
  };

  const validateConta = () => {
    return !!formData.conta;
  };

  const validatePix = () => {
    return !!formData.n_pix;
  };

  const validateCheckForm = () => {
    return formData.checkForm;
  };

  const allowToSubmit = () => {
    // console.log({
    //   bancoId: validateBancoId(),
    //   agencia: validateAgencia(),
    //   conta: validateConta(),
    //   pix: validatePix(),
    //   check: validateCheckForm()
    // })

    return (
      validateBancoId() &&
      validateAgencia() &&
      validateConta() &&
      validatePix() &&
      validateCheckForm()
    );
  };
  useEffect(() => {
    setFormIsValid(allowToSubmit());
    if (allowToSubmit()) {
      setFormInfo({
        banco_id: dataDict && formData.banco_id && dataDict[formData.banco_id] ? dataDict[formData.banco_id] : formData.banco_id,
        conta: `${formData.conta}-${formData.contaLast || 0}`,
        agencia: `${formData.agencia}-${formData.agenciaLast || 0}`,
        n_pix: formData.n_pix,
      });
    }
  }, [allowToSubmit()]);

  const handleInputCheck = (event) => {
    const { name, checked } = event.target;

    setValue(name, checked);
    // checkFormIsValid(event);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    // checkFormIsValid(event);
  };

  const checkFormIsValid = (event) => {
    const { name, value, checked } = event.target;
    let newFormData = formData;
    if (value) {
      newFormData = { ...formData, [name]: value };
    }

    if (checked) {
      newFormData = { ...formData, [name]: checked };
    }

    const formIsValid = allowToSubmit();
    if (formIsValid) {
      setFormIsValid(true);
      setFormInfo(newFormData);
    } else {
      setFormIsValid(false);
    }
  };

  const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } =
    usePerfilPage();

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  dataselectb.forEach((subList) => {
    dataDict[subList[1]] = subList[2];
  });

  return (
    <React.Fragment>
      <Row>
        <TextCenter>
          <CustomText type={"h1"}>Dados Bancários</CustomText>
        </TextCenter>
      </Row>
      <Row>
        <TextCenter>
          <CustomText type={"h6"}>
            Informe a conta que deseja receber seus pagamentos
          </CustomText>
        </TextCenter>
      </Row>

      <Container style={{ marginTop: "10px" }}>
        <FormRow>
          <CustomText type={"h7"}>Banco</CustomText>
          <SearchableSelect
            name="banco_id"
            options={dataselectb.map((field) => ({
              key: field[1],
              value: field[2],
            }))}
            value={formData.banco_id}
            onChange={handleInputChange}
          />
        </FormRow>

        <DisplayRow>
          <FormRow>
            <CustomText type={"h7"}>Número da Agência</CustomText>
            <CustomInput
              name="agencia"
              value={formData.agencia}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <FormRow>
            <CustomText type={"h7"}>Dígito (caso tenha)</CustomText>
            <CustomInput
              placeholder="0"
              name="agenciaLast"
              maxLength={1}
              value={formData.agenciaLast}
              onChange={handleInputChange}
            />
          </FormRow>
        </DisplayRow>

        <DisplayRow>
          <FormRow style={{ flexGrow: 2 }}>
            <CustomText type={"h7"}>
              Conta (Se o dígito for x substitua por 0)
            </CustomText>
            <CustomInput
              name="conta"
              value={formData.conta}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <FormRow style={{ flexGrow: 1 }}>
            <CustomText type={"h7"}>Dígito (caso tenha)</CustomText>
            <CustomInput
              placeholder="0"
              name="contaLast"
              maxLength={1}
              value={formData.contaLast}
              onChange={handleInputChange}
            />
          </FormRow>
        </DisplayRow>

        <FormRow>
          <CustomText type={"h7"}>PIX</CustomText>
          <CustomInput
            name="n_pix"
            value={formData.n_pix}
            onChange={handleInputChange}
          />
        </FormRow>
        <FormRow>
          <Checkbox
            name="checkForm"
            onChange={handleInputCheck}
            isChecked={formData.checkForm}
          >
            Afirmo que a conta fornecida pertence ao meu{" "}
            {hasCnpj ? "cnpj" : "cpf"}
          </Checkbox>
        </FormRow>
      </Container>
    </React.Fragment>
  );
};

export default PersonalBankingStep;
