import React, { useEffect } from 'react';
import { Box, GridItem, Icon, SimpleGrid, useColorModeValue, Flex } from '@chakra-ui/react';
import { CustomGridItem, HeaderContent, HeaderItem } from "../styles";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdApproval, MdCancel, MdRequestPage } from 'react-icons/md';
import { useUserStore } from 'store/reducers/user';
import { CustomSelect } from "styleguide/Select"
import { getOrdersPerDay, getOrderSumMetrics, getProductMetrics, getViewMetrics } from "services/metricsRequest"
import ListOrders from '../components/ListOrders/ListOrders';
import PaymentMethodsGraph from '../components/PaymentMethodsGraph';
import OrdersPerDayGraph from '../components/OrdersPerDayGraph';
import { Text } from "styleguide/Typograph"
import { Spinner } from '@chakra-ui/react'
import {getUserStores} from "controllers/Stores"
import { useQuery } from '@tanstack/react-query';
import { getOrders } from 'controllers/Orders';
import { notifyError } from 'utils/notification';

export default function UserReports() {
	const [totalOrders, setTotalOrders] = React.useState("");
	const [totalPaid, setTotalPaid] = React.useState("");
	const [totalCancelled, setTotalCancelled] = React.useState("");
	const [totalPending, setTotalPending] = React.useState("");
	const [totalChargeback, setTotalChargeback] = React.useState("");
	const [totalBoleto, setTotalBoleto] = React.useState("");
	const [totalCard, setTotalCard] = React.useState("");
	const [totalPix, setTotalPix] = React.useState("");
	const [orderSum, setOrderSum] = React.useState({})
	const [viewMetrics, setViewMetrics] = React.useState([])
	const [ordersPerDay, setOrdersPerDay] = React.useState([])
	const [productsHighlights, setProductsHighlights] = React.useState([])
	const [daysAgo, setDaysAgo] = React.useState(7)
	const [storeOption, setStoreOption] = React.useState<string>(undefined);
	const auth = useUserStore((state) => state.auth);
	const [loading, setLoading] = React.useState(false)
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const token = useUserStore.getState().auth.token;
	const { data: ordersData, isLoading: isLoadingOrders, refetch: refetchOrders } = useQuery(
		["orders", 1, token, { store_id: storeOption }],
		() => getOrders(1, token, { store_id: storeOption }),
		{
			onError: (error) => {
				notifyError("Ops! Tivemos um problema ao trazer os últimos pedidos.")
			}
		}
	);

	const formatFloatValue = (number: any) => {
		var valorTotal = String(number);
		var len = valorTotal.length;
		var valorTotalFormatado = valorTotal.substring(0, len - 2) + "." + valorTotal.substring(len - 2);

		return new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2
			// These options are needed to round to whole numbers if that's what you want.
			//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
			//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		}).format(Number(valorTotalFormatado));

	}

	function formatCurrency(number) {
		const numero = parseFloat(number);
		return numero.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL'
		});
	}
	const [storeArray, setStoresArray] = React.useState([]);
	useEffect(() => {
		(async () => {
			try {
				const stores = await getUserStores();

				setStoresArray(stores.data);
			} catch (err) {
				setStoresArray([])
			}
		})()
	}, []);
	const handleGetTransactions = async (storeId: string, daysAgo: number) => {
		setLoading(false);

		const storeIdResponse = storeId === '' ? undefined : storeId
		const ordersSumMetrics = await getOrderSumMetrics({ daysAgo: daysAgo, storeId: storeIdResponse });
		const viewMetricsResult = await getViewMetrics({ daysAgo: daysAgo, storeId: storeIdResponse });
		const productsHighlightsResult = await getProductMetrics({ daysAgo: daysAgo, storeId: storeIdResponse });
		const ordersPerDayResult = await getOrdersPerDay({ daysAgo: daysAgo, storeId: storeIdResponse });

		if (ordersSumMetrics.success) {
			const data = ordersSumMetrics.data;
			const completedTotal = Number(data.order_results.completed_total || 0);
			const pendingTotal = Number(data.order_results.pending_total || 0);
			const chargebackTotal = Number(data.order_results.chargeback_total || 0);
			const totalOrders = completedTotal + pendingTotal + chargebackTotal;

			setOrderSum(data)
			setTotalPix(data.order_results.pix_total);
			setTotalOrders(formatCurrency(totalOrders));
			setTotalPaid(formatCurrency(completedTotal));
			setTotalCancelled(formatCurrency(data.order_results.cancelled_total || 0));
			setTotalPending(formatCurrency(pendingTotal));
			setTotalChargeback(formatCurrency(chargebackTotal));
		}

		if (viewMetricsResult.success) {
			setViewMetrics(viewMetricsResult.data)
		}

		if (ordersPerDayResult.success) {
			setOrdersPerDay(ordersPerDayResult.data)
		}

		if (productsHighlightsResult.success) {
			setProductsHighlights(productsHighlightsResult.data)
		}

		setLoading(true);
	}
	const handleInputChange = (event) => {
		const selectedValue = event.target.value;
		setDaysAgo(selectedValue); // Atualize o estado com o valor selecionado
		refetchOrders();
	};


	const handleInputStoreChange = (event) => {
		const selectedValue = event.target.value;
		setStoreOption(selectedValue);
		refetchOrders();
	}

	React.useMemo(() => {
		handleGetTransactions(storeOption, daysAgo);
	}, [daysAgo, storeOption]);

	return (
		<Box >
			{!loading && !isLoadingOrders ?
				<Flex direction={"column"}>
					<Spinner />
				</Flex>
				:
				<React.Fragment>
					<SimpleGrid columns={{ base: 2, md: 2, lg: 2, '2xl': 4 }} gap='20px' mt='40px'>
						<GridItem colSpan={{ base: 2, md: 2, lg: 2, '2xl': 4 }}>
							<HeaderContent>
								<HeaderItem>
									<Text type="h2">
										Pedidos
									</Text>
								</HeaderItem>
								<Flex direction={{md: 'row', base: 'column'}}>
									<Box mr={{md: '10px', base: '0'}} mb={{md: '0', base: '5px'}}>
										<CustomSelect
											name="banco_id"
											value={storeOption}
											onChange={handleInputStoreChange}
											required
										>
											<option value="">Todas as lojas</option>
												{storeArray.map((value) => {
													return (
														<option value={value.id}>{value.name}</option>
													)
												}
											)}
										</CustomSelect>
									</Box>
									<Box>
										<CustomSelect
											name="banco_id"
											value={daysAgo}
											onChange={handleInputChange}
											required
										>
											<option value="0">Hoje</option>
											<option value="7">Últimos 7 dias</option>
											<option value="15">Últimos 15 dias</option>
											<option value="30">Últimos 30 dias</option>
										</CustomSelect>
									</Box>
								</Flex>
							</HeaderContent>

						</GridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdRequestPage} color={brandColor} />}
									/>
								}
								name='Pedidos Realizados'
								value={totalOrders || 0}
							/>
						</CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdApproval} color={brandColor} />}
									/>
								}
								name='Pedidos Aprovados'
								value={totalPaid || 0}
							/>
						</CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdCancel} color={brandColor} />}
									/>
								}
								name='Pedidos Cancelados'
								value={totalCancelled || 0}
							/>
						</CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics name='Pedidos pendentes' value={totalPending || 0} />
						</CustomGridItem>
						<CustomGridItem columns={{ base: 1 }}>
							<MiniStatistics name='Chargeback' value={totalChargeback || 0} />
						</CustomGridItem>
						<CustomGridItem columns={{ base: 2 }}>
							<OrdersPerDayGraph orders={ordersPerDay} />
						</CustomGridItem>
						{/*<CustomGridItem columns={{ base: 1 }}>*/}
						{/*	<ProductsHighlights products={productsHighlights} />*/}
						{/*</CustomGridItem>*/}
						<CustomGridItem columns={{ base: 2 }}>
							<PaymentMethodsGraph methods={orderSum} />
						</CustomGridItem>
					</SimpleGrid>
					<SimpleGrid columns={{ base: 2, md: 2, lg: 2, '2xl': 4 }} gap='20px' mt='20px'>
						{ordersData && ordersData?.data?.length > 0 && (
							<CustomGridItem columns={{ base: 2 }}>
								<ListOrders orders={ordersData?.data} />
							</CustomGridItem>
						)}
						{/* <CustomGridItem columns={{base: 1}}>
						Métricas de Conversão e retençao
					</CustomGridItem> */}
						{/*<CustomGridItem columns={{ base: 2 }}>*/}
						{/*	<ViewsMetricsGraph orders={viewMetrics} />*/}
						{/*</CustomGridItem>*/}
						{/* <CustomGridItem columns={{ base: 1 }}>
					<ListPixels orders={listOrders.reverse()} />
				</CustomGridItem> */}
					</SimpleGrid>
				</React.Fragment>
			}
		</Box>
	);
}
