import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Spinner,
} from '@chakra-ui/react';

const InsertSubdomainModal = ({ isOpen, onClose, onProceed, domain, isLoading }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Inserir Subdomínio na Shopify</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        Você está prestes a substituir o checkout padrão da Dubpay (
                        https://seguro.dubpay.com.br) pelo subdomínio:
                    </Text>
                    <Text fontWeight="bold">{domain ? domain.name : ''}</Text>
                    <Text>
                        Certifique-se de que você já inseriu as configurações de DNS (botão Configurar) no site onde comprou o domínio.
                        O subdomínio deve apontar para os valores de configuração corretos.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={onClose} mr={3} isDisabled={isLoading}>Cancelar</Button>
                    <Button colorScheme="green" onClick={onProceed} isLoading={isLoading}>
                        Prosseguir
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default InsertSubdomainModal;
