// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  font-family: \"DM Sans\", sans-serif;\n}\n\noption {\n  color: black;\n}\n\n.cadastro {\n  animation: fadein 3s;\n  -moz-animation: fadein 3s; /* Firefox */\n  -webkit-animation: fadein 3s; /* Safari and Chrome */\n  -o-animation: fadein 3s; /* Opera */\n}\n@keyframes fadein {\n  from {\n      opacity:0;\n  }\n  to {\n      opacity:1;\n  }\n}", "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,yBAAyB,EAAE,YAAY;EACvC,4BAA4B,EAAE,sBAAsB;EACpD,uBAAuB,EAAE,UAAU;AACrC;AACA;EACE;MACI,SAAS;EACb;EACA;MACI,SAAS;EACb;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\nbody {\n  font-family: \"DM Sans\", sans-serif;\n}\n\noption {\n  color: black;\n}\n\n.cadastro {\n  animation: fadein 3s;\n  -moz-animation: fadein 3s; /* Firefox */\n  -webkit-animation: fadein 3s; /* Safari and Chrome */\n  -o-animation: fadein 3s; /* Opera */\n}\n@keyframes fadein {\n  from {\n      opacity:0;\n  }\n  to {\n      opacity:1;\n  }\n}\n@-moz-keyframes fadein { /* Firefox */\n  from {\n      opacity:0;\n  }\n  to {\n      opacity:1;\n  }\n}\n@-webkit-keyframes fadein { /* Safari and Chrome */\n  from {\n      opacity:0;\n  }\n  to {\n      opacity:1;\n  }\n}\n@-o-keyframes fadein { /* Opera */\n  from {\n      opacity:0;\n  }\n  to {\n      opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
