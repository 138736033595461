import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const ConfigModal = ({ isOpen, onClose, data }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configuração de subdomínio</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Subdomínio:</FormLabel>
            <Input type="text" value={data.name} isReadOnly />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Tipo:</FormLabel>
            <Input type="text" value={data.record_type} isReadOnly />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Valor:</FormLabel>
            <Input type="text" value={data.value} isReadOnly />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button backgroundColor="#5505B4" color="white" mr={3} onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfigModal;
