import React from "react";
import {Button, Icon, Box, Flex} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import styled from "styled-components";
import IconButton from "components/iconButton"
import {useUserStore} from "store/reducers/user"
import {IoNotifications,IoChevronDown} from "react-icons/io5";
import { HeaderContent,ImageContainer,LeftContainer,RightContainer,TitleEmail,TitleName} from "./styles/desktopStyles"

const DesktopHeader = () => {
    
    const auth = useUserStore((state) => state.auth);
    return (
        
            <HeaderContent>
                <LeftContainer>
                    <ImageContainer>
                            <img src="/dubpay-logo-white.png" alt="Logo" style={{maxHeight: '60%', maxWidth: '60%', objectFit: 'contain'}}/>
                    </ImageContainer>
                    
                    
                        <IconButton icon={HamburgerIcon}/>
                
                </LeftContainer>
                <RightContainer>
                    {/* Task #232 */}
                {/* <Box>
                    <Icon
					as={IoNotifications}
					color={"white"}
					
					w='20px'
					h='20px'
					
					_hover={{ cursor: 'pointer' }}
				/>
                    
                    </Box> */}
                <Box>
                        <AdminNavbarLinks />
                        
                        </Box>
                    <Box>
                        <Flex direction={"column"} alignItems="space-around">
                            <Box>
                            <TitleName>

                            {auth.user.name}
                            </TitleName>
                            </Box>
                            <Box>
                                <TitleEmail>

                            {auth.user.email}
                                </TitleEmail>
                            </Box>
                        </Flex>
                    </Box>
                    {/* Task #232 */}
                    {/* <Box>
                        
                    <Icon
					as={IoChevronDown}
					color={"white"}
					
					w='20px'
					h='20px'
					
					_hover={{ cursor: 'pointer' }}
				/>
                    
                    </Box> */}
                  
                </RightContainer>
            </HeaderContent>
             
    )
}

export default DesktopHeader