// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Box,
  Button,
  Input,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useState } from "react";
import { useUserStore } from "store/reducers/user";
import { notifyError } from "utils/notification";
import MoneyInput from './MoneyInput';
import { CardContainer, CardRow } from "./styles";

export default function Default(props: {
  buttonClick?: () => Promise<void>;
  name: string;
  growth?: string | number;
  value: string | number;
  description: string;
  buttonText: string;
  disableButton: boolean;
  onClickButton: (userId: number, amount: number) => void;
}) {
  const {
    buttonClick,
    disableButton,
    name,
    buttonText,
    onClickButton,
    value,
    description,
  } = props;
  const { auth } = useUserStore();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const [page, setPage] = useState(true);

  const toogleButton = () => {
    setPage(!page);
  };

  function formatCurrency(number) {
    const numero = parseFloat(number);
    return numero.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  const DisplayValues = () => {
    return (
      <React.Fragment>
        <CardContainer>
          <CardRow>
            {name}
          </CardRow>
          <CardRow>
            <Text fontSize="3xl" as="b">
              {formatCurrency(value || 0)}
            </Text>
            <br />
            <br />
            <Text fontSize="sm" color={"grey"}>
              {description}
            </Text>
          </CardRow>
          <CardRow>
            <Button
              disabled={disableButton}
              bg={"#4b0b61"}
              onClick={() => toogleButton()}
              color={"white"}
            >
              {buttonText}
            </Button>
          </CardRow>
        </CardContainer>
      </React.Fragment>
    );
  };

  const EditValues = (props: { value: Number }) => {
    const [inputValue, setInputValue] = useState("");

    const runCallback = async () => {
      if (Number.isNaN(Number(inputValue))) {
        notifyError("Valor incorreto!");
        setInputValue("");
      } else if (Number(inputValue) > Number(props.value) * 100) {
        notifyError("O valor não pode ser maior que o disponível");
        setInputValue("");
      } else {
        await onClickButton(auth.user.id, Number(inputValue));
        setPage(!page);
      }
    };

    return (
      <React.Fragment>
        <Box>
          <Stat>
            <StatLabel
              lineHeight="100%"
              fontSize={{
                base: "sm",
              }}
            >
              {name}
            </StatLabel>
          </Stat>
        </Box>
        <Box>
          <MoneyInput
            value={inputValue}
            setValue={setInputValue}
            required={true}
            label={"Qual valor você deseja?"}
            id={"1"}
          />
        </Box>
        <Box>
          <Button
            disabled={disableButton}
            bg={"#4b0b61"}
            onClick={() => runCallback()}
            color={"white"}
          >
            Solicitar
          </Button>
          <Button onClick={() => setPage(!page)} color={"purple"}>
            Voltar
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Card h={{ base: "200px", md: "100%", lg: "100%", "2xl": "100%" }}>
      <Flex
        my="auto"
        h="100%"
        flexDirection={"column"}
      >
        {page ? <DisplayValues /> : <EditValues value={Number(value)} />}
      </Flex>
    </Card>
  );
}