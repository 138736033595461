import React, { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import LoadingPage from 'components/Loading/LoadingPage';
import { Box, Button, CloseButton, Divider, Flex, FormControl } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CreateProductRequest, CreateVariationRequest, Product, ProductType } from 'models/Product';
import useStores from 'hooks/stores';
import FormInput from 'components/form/FormInput';
import SelectInput from 'components/form/SelectInput';
import FormTitle from 'components/form/FormTitle';
import MoneyInput from 'components/form/MoneyInput';
import { ImageInput } from 'components/form/ImageInput';
import TextAreaInput from 'components/form/TextAreaInput';
import {
  createOrUpdateVariation,
  createProduct,
  deleteProductImage,
  deleteProductVariation,
  updateProduct
} from 'controllers/Products';
import { UpdateImageInput } from 'components/form/UpdateImageInput';
import { notifyError, notifySuccess } from 'utils/notification';
import { removeVariationImagesFromImages } from 'utils/products';
import { v4 as uuidv4 } from 'uuid';
import { ImageFile } from 'models/File'; // Verifique o caminho correto

type ProductsFormProps = {
  switchShow: Function;
  show: boolean;
  selected?: Product;
  refetch: Function;
};

export default function ProductForm({ switchShow, show, refetch, selected = null }: ProductsFormProps) {
  const [loading, setLoading] = React.useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('R$'); // Estado para o símbolo da moeda

  const { stores, isLoadingStores } = useStores();

  const defaultValues = selected
    ? {
        name: selected.name,
        description: selected.description,
        store_id: selected.store.id,
        type: selected.type,
        variations: selected.variations.map(variation => ({
          name: variation.name,
          price: variation.price,
          currentImage: selected?.images.find(image => image.id === variation.image_id),
          id: variation.id,
          key: uuidv4()
        })),
        currency: selected.currency || 'BRL' as 'BRL' | 'USD' | 'EUR' | 'GBP' | 'JPY' // Garantir o tipo correto
      }
    : {
        type: ProductType.PHYSICAL,
        currency: 'BRL' as 'BRL' | 'USD' | 'EUR' | 'GBP' | 'JPY', // Garantir o tipo correto
      };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch
  } = useForm<CreateProductRequest>({ defaultValues });

  const priceValue = watch('price');
  const currencyValue = watch('currency');
  const imageFiles = watch('images');
  const variations = watch('variations');

  const { append, remove } = useFieldArray({
    control,
    name: 'variations',
  });

  // Atualiza o símbolo da moeda sempre que a moeda selecionada mudar
  useEffect(() => {
    switch (currencyValue) {
      case 'USD':
        setCurrencySymbol('$');
        break;
      case 'EUR':
        setCurrencySymbol('€');
        break;
      case 'GBP':
        setCurrencySymbol('£');
        break;
      case 'JPY':
        setCurrencySymbol('¥');
        break;
      default:
        setCurrencySymbol('R$'); // Padrão BRL
    }
  }, [currencyValue]);

  const handleSave = async (data: CreateProductRequest) => {
    data.variations = filterVariations(data.variations);
    data.images = filterImages(data.images);

    if (selected) {
      await handleUpdate(data);
    } else {
      await handleCreate(data);
    }
  };

  const filterImages = (images: any[]) => {
    if (!images) return images;
    return images.map((image: any) => image.file);
  };

  const filterVariations = (variations: CreateVariationRequest[]) => {
    if (!variations) return variations;
    return variations.map((variation: CreateVariationRequest) => {
      if (!variation.image || !Array.isArray(variation.image)) return variation;
      return {
        ...variation,
        image: variation.image.map((img: ImageFile) => ({ file: img.file, preview: img.preview })) // Certifique-se de mapear corretamente o ImageFile
      };
    });
  };

  const handleCreate = async (data: CreateProductRequest) => {
    setLoading(true);
    try {
      await createProduct(data);
      notifySuccess('Produto criado com sucesso!');
      refetch();
      switchShow();
    } catch (error) {
      notifyError('Erro ao criar o seu produto. Tente Novamente!');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (data: CreateProductRequest) => {
    setLoading(true);
    try {
      if (data.variations) {
        const variationPromises = data.variations.map(
          variation => createOrUpdateVariation(variation, selected.id)
        );
        await Promise.all(variationPromises);
        delete data.variations;
      }
      await updateProduct(selected.id, data);
      notifySuccess('Produto atualizado com sucesso!');
      refetch();
      switchShow();
    } catch (error) {
      notifyError('Erro ao atualizar o seu produto. Tente Novamente!');
    } finally {
      setLoading(false);
    }
  };

  const handleImageDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteProductImage(id);
      await refetch();
      setLoading(false);
      return true;
    } catch (error) {
      notifyError('Erro ao deletar imagem.');
      setLoading(false);
      return false;
    }
  };

  const handleVariationClose = async (index: number, variation: CreateVariationRequest) => {
    if (selected && variation.id) {
      await deleteProductVariation(variation.id); // Remova a verificação de `deleted`
      remove(index);
    } else {
      remove(index);
    }
  };

  React.useEffect(() => {
    if (selected && stores) {
      setValue('store_id', selected.store.id);
    }
  }, [stores]);

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />
      <Card flexDirection="column" marginTop={"10px"} w="100%" px="20px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Box>
          <FormTitle title={selected ? "Editar Produto" : "Novo Produto"} />
          <form onSubmit={handleSubmit(handleSave)}>
            <FormControl rowGap="20px">
              {!selected && (
                <SelectInput
                  register={register}
                  id="store_id"
                  label="Loja *"
                  options={stores?.data.map(item => ({
                    value: item.id.toString(),
                    label: item.name
                  }))}
                  placeholder="Selecione uma loja"
                />
              )}

              <SelectInput
                register={register}
                id="type"
                label="Tipo *"
                options={[
                  { value: "physical", label: "Físico" },
                  { value: "digital", label: "Digital" }
                ]}
                placeholder="Selecione um tipo"
              />

              <FormInput register={register} id="name" label="Nome *" />
              <TextAreaInput register={register} id="description" label="Descrição" required={false} />

              {/* Campo de Preço com Select de Moeda */}
              <Flex gap="10px" align="center">
                <Box flex="0.3">
                  <SelectInput
                    register={register}
                    id="currency"
                    label="Moeda *"
                    options={[
                      { value: "BRL", label: "BRL (R$)" },
                      { value: "USD", label: "USD ($)" },
                      { value: "EUR", label: "EUR (€)" },
                      { value: "GBP", label: "GBP (£)" },
                      { value: "JPY", label: "JPY (¥)" },
                    ]}
                    placeholder="Selecione a moeda"
                  />
                </Box>
                <Box flex="0.7">
                  <MoneyInput
                    setValue={setValue}
                    id={`price`}
                    label="Preço *" // Mantém o campo de preço com formatação
                    value={priceValue}
                  />
                </Box>
              </Flex>

              {selected ? (
                <UpdateImageInput
                  control={control}
                  id="images"
                  label="Imagens"
                  multiple={true}
                  onDelete={handleImageDelete}
                  inputImages={imageFiles}
                  images={selected?.images && removeVariationImagesFromImages(selected).map(image => ({
                    url: image.src,
                    id: image.id
                  }))}
                />
              ) : (
                <ImageInput control={control} id="images" label="Imagens" multiple={true} required={false} images={imageFiles} />
              )}

              {variations && variations.map((variation, index) => (
                <Box pt="20px" key={variation.key}>
                  <Divider />
                  <Box pt="20px">
                    <Flex justify="space-between">
                      <FormTitle title={`Variação #${index + 1}`} />
                      <CloseButton onClick={() => handleVariationClose(index, variation)} />
                    </Flex>
                    <FormInput register={register} id={`variations.${index}.name`} label="Nome *" />
                    <MoneyInput
                      setValue={setValue}
                      id={`variations.${index}.price`}
                      label={`Preço (${currencySymbol}) *`} // Atualiza o símbolo da moeda dinamicamente
                      value={variation.price}
                    />
                    {selected ? (
                      <UpdateImageInput
                        control={control}
                        id={`variations.${index}.image`}
                        label="Imagem"
                        onDelete={handleImageDelete}
                        inputImages={variation.image}
                        images={variation.currentImage ? [{
                          url: variation.currentImage.src,
                          id: variation.currentImage.id
                        }] : null}
                      />
                    ) : (
                      <ImageInput
                        control={control}
                        id={`variations.${index}.image`}
                        label="Imagem"
                        required={false}
                        images={variation.image}
                      />
                    )}
                  </Box>
                </Box>
              ))}

              <Flex mb="10px" justify="right">
                <Button type="button" colorScheme="blue" onClick={() => append({ name: '', price: priceValue, key: uuidv4() })}>
                  Adicionar Variação
                </Button>
              </Flex>

              <Button type="submit" colorScheme="green">Salvar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
