import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
`

export const Body = styled.div`
    // width: 870px;
`

export const FormRow = styled.div`
    width: 100%;
   
`

export const DisplayRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
`

export const Row = styled.div`
    width: 100%;
   
`
export const TextCenter = styled.div`
    text-align: center;
   
`
export const RowFooterButton = styled.div`
    width: 100%;
   display: flex;
   flex-direction: row;
   margin-top: 12px;
   justify-content: space-between;
`
export const RowFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
export const Label = styled.text`
`

export const SecondPageBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 20px;
    
`