import React, { useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, Badge, Button, Flex } from '@chakra-ui/react';
import ConfigModal from './ConfigModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import InsertSubdomainModal from './InsertSubdomainModal';

const CheckTableDomain = ({ tableData, onDelete, onInsertSubdomain }) => {
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedDomain, setSelectedDomain] = useState(null);

  const openConfigModal = (data) => {
    setSelectedData(data);
    setIsConfigModalOpen(true);
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
  };

  const openDeleteModal = (domain) => {
    setSelectedDomain(domain);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'processing':
        return {
          color: 'yellow.800',
          bgColor: 'yellow.100',
        };
      case 'active':
        return {
          color: 'green.800',
          bgColor: 'green.100',
        };
      case 'inactive':
        return {
          color: 'red.800',
          bgColor: 'red.100',
        };
      default:
        return {
          color: 'gray.800',
          bgColor: 'gray.100',
        };
    }
  };

  const handleDelete = async () => {
    if (onDelete && selectedDomain) {
      await onDelete(selectedDomain);
      closeDeleteModal();
    }
  };

  return (
    <>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Loja</Th>
            <Th>Subdomínio</Th>
            <Th>Status</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((row, index) => (
            <Tr key={index}>
              <Td>{row.store}</Td>
              <Td>{row.domain}</Td>
              <Td>
                <Badge
                  color={getStatusStyles(row.status).color}
                  bg={getStatusStyles(row.status).bgColor}
                  borderRadius="full"
                  px="2"
                >
                {row.status === 'processing' ? 'Processando' : row.status === 'active' ? 'Ativo' : row.status === 'inactive' ? 'Inativo' : row.status}
                </Badge>
              </Td>
              <Td>
                <Flex align="center">
                  {row.status !== 'active' && row.status !== 'inactive' && (
                    <Button onClick={() => openConfigModal(row)} mr="2">Configurar</Button>
                  )}
                  <Button colorScheme="red" onClick={() => openDeleteModal(row.domain)}>Deletar</Button>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ConfigModal isOpen={isConfigModalOpen} onClose={closeConfigModal} data={selectedData} />
      <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} onConfirm={handleDelete} />
    </>
  );
};

export default CheckTableDomain;