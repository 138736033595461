// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Progress
} from "@chakra-ui/react";
import styled from "styled-components";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { FaCoins, FaEthereum, FaFlag } from "react-icons/fa";
import routes from "routes";
import { useHistory } from "react-router-dom";
import { useUserStore } from "store/reducers/user";
import { IoEllipsisVertical } from "react-icons/io5";
import axios from "axios";

// Importar o caminho das imagens diretamente
const stampsPath = "/assets/img/profile/stamps/";

export default function HeaderLinks() {
  const logout = useUserStore((state) => state.logout);
  const { name } = useUserStore((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const [tax, setTax] = useState(null);
  const [warrantyTax, setWarrantyTax] = useState(null);
  const [currentStamp, setCurrentStamp] = useState(null);
  const [nextStamp, setNextStamp] = useState(null);
  const [completedTotal, setCompletedTotal] = useState(0);

  const handleClose = () => {
    setIsOpen(false);
  };

  const fetchTax = async () => {
    const token = useUserStore.getState().auth.token;
    const adminUrl = `/v1/users/`;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedTax =
        result["data"]["data"]["seller"]["negotiated_tax_rate"];
      const fetchedWarrantyTax =
        result["data"]["data"]["seller"]["negotiated_warranty_tax"];
      setTax(fetchedTax);
      setWarrantyTax(fetchedWarrantyTax);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchStamps = async () => {
    const token = useUserStore.getState().auth.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-stamps`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        const current = response.data.current_stamp;
        const next = response.data.next_stamp;
        const completedTotalValue = Number(response.data.completed_total);

        // Atualiza o selo atual se o valor total ultrapassar o threshold
        if (next && completedTotalValue >= Number(next.threshold)) {
          setCurrentStamp(next);
          setNextStamp(null);
        } else {
          setCurrentStamp(current);
          setNextStamp(next);
        }
        setCompletedTotal(completedTotalValue);
      } else {
        console.error("Error fetching stamps: Invalid response", response);
      }
    } catch (error) {
      console.error("Error fetching stamps:", error);
    }
  };

  useEffect(() => {
    fetchTax();
    fetchStamps();
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = "white";
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = "navy.900";
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = "whiteAlpha.200";
  const { push } = useHistory();

  const IconDesktop = styled.div`
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
  `;
  const IconMobile = styled.div`
    display: none;
    @media (max-width: 1024px) {
      display: flex;
    }
  `;

  return (
    <Flex
      w={"100%"}
      alignItems="center"
      flexDirection="row"
      flexWrap={"unset"}
      p="10px"
      boxShadow={shadow}
    >
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalContent
          style={{
            background: "linear-gradient(45deg, #40009B, #7000D4)",
          }}
        >
          <ModalHeader>
            <Flex align="middle" justify="center">
              <FaFlag color="gold" style={{ marginTop: "13px" }} />
              <span
                style={{
                  fontSize: "36px",
                  color: "white",
                  marginLeft: "10px",
                  marginRight: "10px",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
                }}
              >
                Plano Gold
              </span>
              <FaCoins color="gold" style={{ marginTop: "13px" }} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa por Transação:{" "}
                {(Math.round(tax * 100) / 100).toFixed(2).replace(".", ",")}%
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                (para Cartão, Boleto e Pix)
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Garantia:{" "}
                {(Math.round(warrantyTax * 100) / 100)
                  .toFixed(2)
                  .replace(".", ",")}
                %
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Antecipação: 1,90%
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Saque: R$ 4,50
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Gateway: R$ 1,00
              </p>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Menu>
        <MenuButton p="0px">
          <IconDesktop>
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={name}
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </IconDesktop>
          <IconMobile>
            <Icon
              as={IoEllipsisVertical}
              color={"white"}
              w="20px"
              h="20px"
              _hover={{ cursor: "pointer" }}
            />
          </IconMobile>
        </MenuButton>
        <MenuList
          border="1px solid black"
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
        >
          <Flex flexDirection="column" p="10px">
            {currentStamp && (
              <>
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  px="14px"
                >
                  <Flex align="center">
                    <Image
                      boxSize="60px" // Aumenta o tamanho da imagem
                      src={require(`assets/img/profile/stamps/${currentStamp.image_path}`)}
                      alt={currentStamp.name}
                      mr="10px"
                    />
                    <Text fontSize="sm">{currentStamp.name}</Text>
                  </Flex>
                </MenuItem>
                {nextStamp && nextStamp.threshold ? (
                  <MenuItem
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Flex flexDirection="column" align="center" w="100%">
                      <Text fontSize="sm" mb="5px">
                        {completedTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / {Number(nextStamp.threshold).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} -{" "}
                        {nextStamp.name}
                      </Text>
                      <Progress
                        value={(completedTotal / nextStamp.threshold) * 100}
                        size="md"
                        colorScheme="green"
                        w="100%"
                        h="8px"
                        borderRadius="4px"
                        border="1px solid black"
                      />
                    </Flex>
                  </MenuItem>
                ) : (
                  <MenuItem
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Text fontSize="sm" mb="5px" textAlign="center">
                      Parabéns! Chegou no último nível!
                    </Text>
                  </MenuItem>
                )}
              </>
            )}
            {/* Task #232 */}
            {/* {<MenuItem
              onClick={() => {
                push("/admin/perfil");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Meu perfil</Text>
            </MenuItem>} */}
            {/* {<MenuItem
              onClick={() => {
                push("/admin/alterarEmail");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Alterar email</Text>
            </MenuItem>}
            {<MenuItem
              onClick={() => {
                push("/admin/alterarSenha");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Alterar senha</Text>
            </MenuItem>} */}
            {<MenuItem
              onClick={handleOpen}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Plano</Text>
            </MenuItem>}
            {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Config. Notificações</Text>
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                logout();
                push("/auth");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Sair</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
